.keyboard_button_default {
    font-size: 24px;
    height: 80px !important;
}

.keyboard_button_action {
    font-size: 30px;
    font-weight: bold;
    color: #ffffff;
    background: #7ac968 !important;
    height: 80px !important;
}

.keyboard_button_action_disabled {
    height: 80px !important;
    font-size: 30px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
    border-color: #d9d9d9;
    background: rgba(0, 0, 0, 0.04) !important;
}

/* In your CSS file */
@keyframes pulse {
    0% {
        transform: scale(0.75);
    }
    50% {
        transform: scale(1.1); /* You can adjust the scale value to get the desired effect */
    }
    100% {
        transform: scale(0.75);
    }
}


.pulse {
    animation: pulse 2s infinite;
}